import React, { useState, useEffect } from "react";
// import { parse } from 'querystring';
import PageLayout from "../components/utils/PageLayout";
import {
    Checkbox,
    Divider,
    Form,
    Spin,
    notification,
    Input,
    Radio,
} from "antd";
// import bus from '../assets/img/bus.jpg';
import { Skeleton } from "antd";
import PhoneInput from "react-phone-number-input";
//import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { RouteConstants } from "../helpers/constants";
import Loader from "../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { GuestSchedule, Seat } from "../types";
import { api } from "../helpers/api";
import moment from "moment";
import CustomButton from "../components/CustomBtn";

const TripCodeBooking = () => {
    const required = {
        0: ["first_name", "last_name"],
    };
    const [form] = Form.useForm();
    const [schedulDetails, setScheduleDetails] = useState<
        GuestSchedule | undefined
    >();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    //   const [seatArray, setSeatArray] = useState<any[]>([]);
    const [phone, setPhone] = useState<string | undefined>();
    const [ice_phone, setICEPhone] = useState<string | undefined>();
    const [seatsLoading, setSeatsLoading] = useState(false);
    //const [btnDisabled, setBtnDisabled] = useState(false);
    const [selectedSeat, setSelectedSeat] = useState<any>();
    const [busSeats, setBusSeats] = useState<Seat[]>([]);
    const [prepaid, setPrepaid] = useState<any>(false);

    const navigate = useNavigate();
    const params = useParams();

    const handleSubmit = () => {
        if (!selectedSeat) {
            notification.error({
                message: "Please select a seat to continue",
                duration: 10,
            });
        } else if (!phone) {
            notification.error({
                message: "Phone is required",
                duration: 10,
            });
        } else if (!ice_phone) {
            notification.error({
                message: "ICE Phone is required",
                duration: 10,
            });
        } else {
            form.validateFields(required[0])
                .then(async (v) => {
                    //console.log(v);
                    try {
                        setSubmitting(true);
                        const values = form.getFieldsValue();
                        console.log(values);
                        // const usr = JSON.parse(user);

                        const payload = {
                            seat_id: Number(selectedSeat?.id),
                            // pickup_id: values?.pickup_id && values?.pickup_id,
                            schedule_id: Number(params?.id),
                            minors: 0,
                            luggage_description: "description",
                            occupant_type: "ADULT",

                            first_name: values?.first_name,
                            last_name: values?.last_name,
                            phone: phone,
                            ice_phone: ice_phone,

                            prepaid: prepaid,
                        };

                        await api.post(
                            `${RouteConstants.SINGLE_GUEST_SCHEDULE}/${params?.id}/book`,
                            {
                                ...payload,
                            }
                        );
                        notification.success({
                            message: "Request successful",
                        });
                        navigate("/");

                        setSubmitting(false);
                    } catch (e: any) {
                        //console.log(e);
                        setSubmitting(false);
                        notification.error({
                            message: e?.response?.data?.message,
                        });
                    }
                })
                .catch((e) => {
                    notification.error({
                        message: "Fill required fields to continue",
                    });
                });
        }
    };

    const handleSeatSelection = (seat: any) => {
        const seatObj = busSeats.find(
            (selectedSeat) => selectedSeat?.id === seat
        );
        setSelectedSeat(seatObj);
    };

    const getScheduleDetails = async () => {
        setLoading(true);
        try {
            const res = await api.get(
                `${RouteConstants.SINGLE_GUEST_SCHEDULE}/${params?.id}`
            );
            setScheduleDetails(res.data?.payload);
            setLoading(false);
        } catch (e: any) {
            //console.log(e);
            setLoading(false);
            notification.error({ message: e?.response?.data?.message });
        }
    };

    const getScheduleSeats = async () => {
        setSeatsLoading(true);
        try {
            const res = await api.get(
                `${RouteConstants.SINGLE_GUEST_SCHEDULE}/${params?.id}/seats`
            );
            // setBusSeats(
            //   res.data?.payload?.items?.map((item: Seat) => ({
            //     label: `Seat ${item?.number}`,
            //     value: item?.id,
            //     status: item?.status,
            //   }))
            // );
            setBusSeats(res.data?.payload?.items);
            setSeatsLoading(false);
        } catch (e) {
            console.log(e);
            setSeatsLoading(false);
        }
    };

    useEffect(() => {
        //console.log(params);
        getScheduleDetails();
        getScheduleSeats();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return (
            <PageLayout>
                <div className="mt-20 bg-white min-h-[100vh]">
                    <div className="flex justify-center pt-20 sm:gap-7  w-full ">
                        <div className="w-[30%]">
                            <div className=" border rounded-md p-4 border-gray-200">
                                <Skeleton active paragraph round />
                            </div>
                            <div className="mt-5 border rounded-md p-4 border-gray-200">
                                <Skeleton active paragraph round />
                            </div>
                        </div>
                        <div className="w-[20%] rounded-m p-4 border border-gray-200">
                            <Skeleton active paragraph round />
                        </div>
                    </div>
                </div>
            </PageLayout>
        );
    }

    return (
        <PageLayout>
            <div className="pt-[4rem] px-[1rem] sm:px-[8rem] bg-white pb-20 min-h-screen">
                <Spin
                    spinning={submitting}
                    className="py-10 md:py-20 min-h-[50vh] flex justify-center items-center"
                    indicator={
                        <Loader
                            float
                            bgColor="transparent appear"
                            width="w-16"
                            height="h-16"
                        />
                    }
                >
                    <div className="pt-20 h-screen ">
                        <Form layout="vertical" form={form}>
                            {schedulDetails ? (
                                <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
                                    <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                                        <div className="rounded-md border-gray-200 border p-3 shadow-sm">
                                            <h1 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                                                {
                                                    schedulDetails?.route?.from
                                                        ?.name
                                                }{" "}
                                                to{" "}
                                                {
                                                    schedulDetails?.route?.to
                                                        ?.name
                                                }
                                            </h1>
                                            <div>
                                                <div className="flex gap-1 items-center">
                                                    <div>Travel Date</div>
                                                    <div>-</div>
                                                    <p className="py-3 text-gray-600">
                                                        {moment(
                                                            schedulDetails?.departures_at
                                                        ).format("MMM Do YY")}
                                                    </p>
                                                </div>
                                                <div className="flex gap-1 items-center">
                                                    {/* <img src={bus} className='w-8 h-5' alt='bus' />
                          <p>Branch</p> */}
                                                    <div>-</div>
                                                    <p className="text-gray-600">
                                                        {
                                                            schedulDetails
                                                                ?.branch?.name
                                                        }
                                                    </p>
                                                </div>
                                                <div className="flex gap-1 items-center pt-2">
                                                    <p>Operated by</p>
                                                    <div>-</div>
                                                    <p className="text-gray-600">
                                                        {
                                                            schedulDetails
                                                                ?.branch
                                                                ?.company?.code
                                                        }{" "}
                                                        {
                                                            schedulDetails
                                                                ?.branch
                                                                ?.company?.name
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rounded-md border-gray-200 border p-3 shadow-sm mt-5">
                                            <h2 className="text-gray-900 text-lg font-semibold">
                                                Personal Info
                                            </h2>
                                            <p className="text-gray-500 mb-6">
                                                Provide information about the
                                                traveler
                                            </p>

                                            <div className="flex gap-3 items-center">
                                                <Form.Item
                                                    colon={false}
                                                    className="w-1/2"
                                                    label="First name"
                                                    name="first_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "This field is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Doe"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    colon={false}
                                                    className="w-1/2"
                                                    label="Last name"
                                                    name="last_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "This field is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Kweku"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Form.Item
                                                colon={false}
                                                label="Phone number"
                                                name="phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "This field is required",
                                                    },
                                                ]}
                                            >
                                                <PhoneInput
                                                    value={phone}
                                                    onChange={(e) => {
                                                        let formatted =
                                                            e?.replace(
                                                                /\s+/g,
                                                                ""
                                                            );
                                                        setPhone(formatted);
                                                    }}
                                                    placeholder="0244123456"
                                                    className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                                    defaultCountry="GH"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                colon={false}
                                                label="ICE Phone number"
                                                name="ice_phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "This field is required",
                                                    },
                                                ]}
                                            >
                                                <PhoneInput
                                                    value={ice_phone}
                                                    onChange={(e) => {
                                                        let formatted =
                                                            e?.replace(
                                                                /\s+/g,
                                                                ""
                                                            );
                                                        setICEPhone(formatted);
                                                    }}
                                                    placeholder="0244123456"
                                                    className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                                    defaultCountry="GH"
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Seat selection */}
                                        <div className="rounded-md border-gray-200 border p-3 shadow-sm mt-5">
                                            <h2 className="text-gray-900 text-lg font-semibold">
                                                Select Seat
                                            </h2>
                                            <p className="text-gray-500 mb-6">
                                                Select your preferred seat
                                                number from the list
                                            </p>
                                            <div>
                                                <Form.Item name="seat_id">
                                                    {busSeats.length > 0 ||
                                                    seatsLoading ? (
                                                        <Radio.Group>
                                                            <div className="flex gap-5">
                                                                <div className="grid grid-cols-4 border-t-[5px] rounded-t-xl border-b-[5px] rounded-b-xl pt-10">
                                                                    {busSeats.map(
                                                                        (
                                                                            seat
                                                                        ) => (
                                                                            <Radio
                                                                                key={
                                                                                    seat?.id
                                                                                }
                                                                                value={
                                                                                    seat?.id
                                                                                }
                                                                                className="pt-2 guestSeatSelection"
                                                                                disabled={
                                                                                    seat?.status ===
                                                                                    1
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    handleSeatSelection(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className=" relative flex items-center justify-center">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className={`${
                                                                                            seat?.status ===
                                                                                            1
                                                                                                ? "text-oya-ghana-red"
                                                                                                : "text-green-600"
                                                                                        }`}
                                                                                        width="54"
                                                                                        height="49"
                                                                                        viewBox="0 0 54 49"
                                                                                        fill="none"
                                                                                    >
                                                                                        <rect
                                                                                            y="6.23016"
                                                                                            width="5.25"
                                                                                            height="37.1907"
                                                                                            rx="2.625"
                                                                                            className="fill-current"
                                                                                            fill="none"
                                                                                        />
                                                                                        <rect
                                                                                            x="48.75"
                                                                                            y="6.23016"
                                                                                            width="5.25"
                                                                                            height="37.1907"
                                                                                            rx="2.625"
                                                                                            className="fill-current"
                                                                                            fill="none"
                                                                                        />
                                                                                        <path
                                                                                            d="M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z"
                                                                                            className="fill-current"
                                                                                            fill="none"
                                                                                        />
                                                                                        <path
                                                                                            d="M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z"
                                                                                            className="fill-current"
                                                                                            fill="none"
                                                                                        />
                                                                                    </svg>

                                                                                    <span className="text-white text-oya-ghana-header-h7 absolute text-center pb-2">
                                                                                        {
                                                                                            seat?.number
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </Radio>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="flex gap-5 items-center mb-3">
                                                                        <div className="w-5 h-5 bg-green-600" />
                                                                        <div>
                                                                            Available
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex gap-2 items-center">
                                                                        <div className="w-5 h-5 bg-oya-ghana-red" />
                                                                        <div>
                                                                            Unavailable
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Radio.Group>
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                No seats
                                                                available for
                                                                this schedule
                                                            </div>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Sumary */}
                                    <div className="mt-4 lg:row-span-3 lg:mt-0">
                                        <div className="text-xl font-semibold">
                                            Trip Summary
                                        </div>

                                        <div className="my-3">
                                            <div className="flex justify-between items-center">
                                                <div>Traveler</div>
                                                <div>Adult</div>
                                            </div>
                                            <div className="flex justify-between items-center mt-2">
                                                <div>Seat Number</div>
                                                {selectedSeat ? (
                                                    <div>
                                                        {selectedSeat?.number}
                                                    </div>
                                                ) : (
                                                    <div>No Seat selected</div>
                                                )}
                                            </div>
                                        </div>
                                        <Divider />
                                        {/* Personal info */}
                                        <div>
                                            <div className="flex justify-between items-center">
                                                <div>Name</div>
                                                {form.getFieldValue(
                                                    "first_name"
                                                ) &&
                                                    form.getFieldValue(
                                                        "last_name"
                                                    ) && (
                                                        <div>
                                                            {form.getFieldValue(
                                                                "first_name"
                                                            )}{" "}
                                                            {form.getFieldValue(
                                                                "last_name"
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="flex justify-between items-center my-2">
                                                <div>Phone</div>
                                                {phone && <div>{phone}</div>}
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div>ICE Contact</div>
                                                {ice_phone && (
                                                    <div>{ice_phone}</div>
                                                )}
                                            </div>
                                        </div>
                                        <Divider />
                                        <Form.Item
                                            name="prepaid"
                                            className="pt-3"
                                        >
                                            <Checkbox
                                                onChange={(e) => {
                                                    setPrepaid(
                                                        e.target.checked
                                                    );
                                                }}
                                            >
                                                Prepaid
                                            </Checkbox>
                                        </Form.Item>
                                        <button
                                            data-testid="submitTripCodeBooking"
                                            type="button"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-oya-ghana-red py-3 px-8 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        >
                                            Submit
                                        </button>
                                    </div>

                                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
                                        {/* Description and details */}
                                        <div>
                                            <div className="space-y-6">
                                                {/* <p className='text-base text-gray-900'>
                    {product.description}
                  </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col justify-center items-center ">
                                    <div className="flex flex-col justify-center items-center shadow-sm rounded-md border p-4">
                                        <InformationCircleIcon className="w-20 h-20 text-oya-ghana-red" />
                                        <div className="py-4 sm:text-2xl text-xl">
                                            Schedule not found
                                        </div>
                                        <CustomButton
                                            data-testid="goBackHome"
                                            onClick={() => navigate("/")}
                                        >
                                            Go back home
                                        </CustomButton>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                </Spin>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </PageLayout>
    );
};

export default TripCodeBooking;
