import React, { useState, useEffect, useCallback } from "react";
import { Skeleton, Row, Col, Pagination } from "antd";
import SearchFilter from "../components/Search";
import { Schedule } from "../types";
import { api } from "../helpers/api";
import CustomButton from "../components/CustomBtn";
import notFound from "../assets/img/not_found.svg";
import ScheduleCard from "../components/Schedules/ScheduleCard";
import ReactGA from "react-ga";
import BusType from "../components/Filters/BusType";
import Comfort from "../components/Filters/Comfort";
import { useMediaQuery } from "@mui/material";
import { RouteConstants } from "../helpers/constants";
import PageLayout from "../components/utils/PageLayout";
import { parse } from "querystring";

import TripCode from "../components/Search/TripCode";
import { getCookie } from "../helpers/utils";
import { accounts } from "../helpers/api";
import AdvanceTicketing from "../components/Schedules/AdvanceTicketing";
import Main from "../mobile/Main";
import VerifyModal from "../mobile/components/VerifyModal";

const Home = () => {
  const [info] = useState(
    parse(window.location && window.location.search.replace("?", ""))
  );
  const token = getCookie("oya_token");
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [findTripActive, setFindTripActive] = useState(true);
  const [tripCodeActive, setTripCodeActive] = useState(false);
  const [bookAdvanceActive, setBookAdvance] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [busType, setBusType] = useState<any>([]);
  const [isPremium, setIsPremium] = useState<any>();

  const [tripCode, setTripCode] = useState<string | null>();
  const [pickupId, setPickupId] = useState<number | undefined>();
  const [destinationId, setDestinationId] = useState<number | undefined>();
  const [isMobile, setIsMobile] = useState(true);

  const [notVerified, setNotVerified] = useState(false);

  //const token = getCookie("oya_token");

  const isLarge = useMediaQuery("(min-width:1024px)");

  const [meta, setMeta] = useState({
    page: 0,
    limit: 12,
    offset: 0,
    total_pages: null,
    total: 0,
  });

  const handleTripCode = useCallback(
    (value: string | undefined) => {
      setTripCode(value);
    },
    // eslint-disable-next-line
    [tripCode]
  );

  const getRides = async () => {
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [`${startOfDay.toISOString()}`, `${endofDay.toISOString()}`],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    if (tripCode) {
      defaultFilter.push({
        f: "code",
        o: "=",
        p: [`${tripCode}`],
      });
    }

    if (busType && busType.length > 0) {
      //console.log(busType);
      let bus_types = busType?.map((item: any) => `${item?.value}`);
      defaultFilter.push({
        f: "bus_type.id",
        o: "in",
        p: bus_types,
      });
    }

    if (isPremium === false || isPremium === true) {
      defaultFilter.push({
        f: "premium",
        o: "=",
        p: [isPremium],
      });
    }

    if (pickupId && destinationId) {
      defaultFilter.push(
        {
          f: "route.from.id",
          o: "=",
          p: [pickupId],
          r: "AND",
        },
        {
          f: "route.to.id",
          o: "=",
          p: [destinationId],
        }
      );
    } else if (pickupId) {
      defaultFilter.push({
        f: "route.from.id",
        o: "=",
        p: [pickupId],
      });
    } else if (destinationId) {
      defaultFilter.push({
        f: "route.to.id",
        o: "=",
        p: [destinationId],
      });
    }

    try {
      const response = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${meta.limit}&offset=${Number(meta.limit * pageIndex)}`
      );

      if (response.data?.payload?.items?.length > 0) {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        if (response.data?.payload?.total > meta?.limit) {
          setHasMore(true);
          setSchedules(response.data?.payload?.items);
        } else {
          setSchedules(response.data?.payload?.items);
        }
      } else {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        setHasMore(false);
        setSchedules(response.data?.payload?.items);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (page: number, pageSize: number) => {
    setPageIndex(page - 1);
    setMeta((prev) => ({
      ...prev,
      limit: pageSize,
    }));
  };

  const getUserData = async () => {
    if (token) {
      try {
        const res = await accounts.get(`${RouteConstants.PROFILE}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data?.payload?.verified === true) {
          setNotVerified(false);
        } else {
          setNotVerified(true);
        }
      } catch (e) {
        //setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (token) {
      getUserData();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    getRides();
    // eslint-disable-next-line
  }, [
    pickupId,
    destinationId,
    tripCode,
    pageIndex,
    meta.limit,
    busType,
    isPremium,
  ]);

  useEffect(() => {
    if (info?.advance_ticket === "ACTIVE") {
      //console.log(info);
      setBookAdvance(true);
      setTripCodeActive(false);
      setFindTripActive(false);
    } else {
      localStorage.removeItem("trd");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <VerifyModal
        isOpen={notVerified}
        handleClose={() => setNotVerified(false)}
      />
      {isMobile ? (
        <Main />
      ) : (
        <PageLayout>
          <div className="flex justify-center items-center w-full pt-20">
            <div className="w-full py-8 flex flex-col ml-[8rem]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-4">
                    {findTripActive ? (
                      <div
                        className={
                          findTripActive
                            ? `text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs `
                            : `sm:text-[16px] rounded-lg border  text-xs text-oya-red-500`
                        }
                      >
                        Find a trip
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setFindTripActive(true);
                          setTripCodeActive(false);
                          setBookAdvance(false);
                        }}
                        className="sm:text-[16px] text-oya-red-500  text-xs py-[6px] px-[2px] sm:px-[24px]"
                      >
                        Find a trip
                      </button>
                    )}
                    {tripCodeActive ? (
                      <div
                        className={
                          tripCodeActive
                            ? `text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs `
                            : `sm:text-[16px] text-oya-red-50 text-xs`
                        }
                      >
                        Use trip code
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setFindTripActive(false);
                          setTripCodeActive(true);
                          setBookAdvance(false);
                        }}
                        className="sm:text-[16px] text-xs text-oya-red-500 py-[6px] px-[2px] sm:px-[24px]"
                      >
                        Use trip code
                      </button>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      setFindTripActive(false);
                      setTripCodeActive(false);
                      setBookAdvance(true);
                      // if (!token) {
                      //   openAuthModal(true);
                      // }
                    }}
                    className={
                      bookAdvanceActive
                        ? "text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs"
                        : "sm:text-[16px] text-xs text-oya-red-500 py-[6px] px-[2px] sm:px-[24px]"
                    }
                  >
                    Book a trip in advance
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center w-full">
                {findTripActive && (
                  <SearchFilter
                    handleSearch={(pickup, destination) => {
                      //handleRouteSearch(value);

                      setPickupId(pickup);
                      setDestinationId(destination);
                    }}
                  />
                )}
                {tripCodeActive && (
                  <TripCode
                    handleTripCodeChange={(value) => handleTripCode(value)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="sm:px-[8rem] px-[2rem] sm:pt-[2rem] min-h-screen">
            {bookAdvanceActive ? (
              <AdvanceTicketing />
            ) : (
              <div className="flex gap-3">
                <div className="w-full h-screen">
                  <div className="flex items-center w-full justify-between lg:justify-start">
                    <div className="font-semibold m-3 sm:text-[24px] text-lg text-oya-gray lg:flex-[0.3]">
                      Available Trips
                    </div>
                    {isLarge && (
                      <>
                        <div className="flex gap-4  items-center flex-[0.65]">
                          {/* <DateFilter /> */}

                          <Comfort
                            handleChange={(value) => {
                              setIsPremium(value);
                            }}
                          />
                          <BusType
                            handleChange={(id) => {
                              setBusType(id);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex gap-3 items-center w-full justify-center mb-5 ">
                    {!isLarge && (
                      <>
                        <div className="flex gap-4 items-center justify-center flex-wrap">
                          <Comfort
                            handleChange={(value) => {
                              setIsPremium(value);
                            }}
                          />
                          <BusType
                            handleChange={(id) => {
                              setBusType(id);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {loading ? (
                    <Row className="mx-3 gap-3">
                      <Col xs={24} md={12} lg={7}>
                        <div className="p-2 border shadow rounded-md">
                          <Skeleton paragraph active />
                        </div>
                      </Col>
                      <Col xs={24} md={12} lg={7}>
                        <div className="p-2 border shadow rounded-md">
                          <Skeleton paragraph active />
                        </div>
                      </Col>
                      <Col xs={24} md={12} lg={7}>
                        <div className="p-2 border shadow rounded-md">
                          <Skeleton paragraph active />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {schedules.length <= 0 ? (
                        <div className=" py-3 ">
                          <div className="flex flex-col justify-center items-center h-full">
                            <img
                              className="w-[50%] sm:w-[40%] h-auto mt-8"
                              src={notFound}
                              alt="404"
                            />
                            <div className="font-bold sm:text-xl text-center mt-8 text-gray-500">
                              No schedules available
                            </div>
                            <div className="mt-2">
                              <CustomButton
                                onClick={() => {
                                  setBookAdvance(true);
                                  setFindTripActive(false);
                                  setTripCodeActive(false);
                                  // if (!token) {
                                  //   openAuthModal(true);
                                  // }
                                }}
                              >
                                Book for advance ticket
                              </CustomButton>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                            {schedules.map((item, index) => (
                              <div className="w-full min-w-[220px]" key={index}>
                                <ScheduleCard item={item} />
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="pt-10 w-full flex justify-end items-end">
                    {hasMore && (
                      <div className="pagination w-full justify-end items-end">
                        <Pagination
                          onChange={(page, pageSize) =>
                            handlePagination(page, pageSize)
                          }
                          defaultPageSize={meta.limit}
                          total={meta.total}
                        />
                      </div>
                    )}
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            )}
          </div>
        </PageLayout>
      )}
    </React.Fragment>
  );
};

export default Home;
