import React, { useState } from "react";
import { Input, notification } from "antd";

import { Spinner } from "@material-tailwind/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { RouteConstants } from "../../helpers/constants";
import { api } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/utils";

const TripCodeSearch = () => {
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const navigate = useNavigate();
  const [tripCode, setTripCode] = useState<string>("");

  const token = getCookie("oya_token");

  const validateInput = (value: string) => {
    const regex = /^\d{6}$/;
    if (regex.test(value)) {
      setErrorCode("");
      return true;
    } else {
      setErrorCode("Trip code must be exactly 6 digits");
      return false;
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (value === "" || /^\d*$/.test(value)) {
      setTripCode(value);
      if (value.length === 6) {
        validateInput(value);
      } else {
        setErrorCode("Trip code must be exactly 6 digits");
      }
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [`${startOfDay.toISOString()}`, `${endofDay.toISOString()}`],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      },
      {
        f: "code",
        o: "=",
        p: [`${tripCode}`],
      }
    );

    try {
      const response = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}`
      );

      if (response?.data?.payload?.items?.length > 0) {
        if (token) {
          navigate(`/trip/${response?.data?.payload?.items[0]?.id}`);
        } else {
          navigate(`/guest/${response?.data?.payload?.items[0]?.id}`);
        }
        setErrorCode("");
      } else {
        setErrorCode("No schedules found for this trip code.");
      }
      setLoading(false);
    } catch (error: any) {
      //console.log(error);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="w-[85%]  p-2 flex gap-3">
        <Input
          onChange={handleChange}
          value={tripCode}
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M14.375 27.1875C7.3125 27.1875 1.5625 21.4375 1.5625 14.375C1.5625 7.3125 7.3125 1.5625 14.375 1.5625C21.4375 1.5625 27.1875 7.3125 27.1875 14.375C27.1875 21.4375 21.4375 27.1875 14.375 27.1875ZM14.375 3.4375C8.3375 3.4375 3.4375 8.35 3.4375 14.375C3.4375 20.4 8.3375 25.3125 14.375 25.3125C20.4125 25.3125 25.3125 20.4 25.3125 14.375C25.3125 8.35 20.4125 3.4375 14.375 3.4375Z"
                fill="#FF5A55"
              />
              <path
                d="M27.5 28.4375C27.2625 28.4375 27.025 28.35 26.8375 28.1625L24.3375 25.6625C23.975 25.3 23.975 24.7 24.3375 24.3375C24.7 23.975 25.3001 23.975 25.6626 24.3375L28.1626 26.8375C28.5251 27.2 28.5251 27.8 28.1626 28.1625C27.9751 28.35 27.7375 28.4375 27.5 28.4375Z"
                fill="#FF5A55"
              />
            </svg>
          }
          placeholder="Enter trip code"
          className="w-full"
          type="number"
          maxLength={6}
          data-testid="tripCodeSearchInput"
        />
        <div className="rounded-lg bg-oya-red-100 h-[40px] justify-center items-center w-[60px] flex">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Spinner className="text-white" />
            </div>
          ) : (
            <button className="p-2" onClick={() => handleSearch()}>
              <ArrowRightIcon className="w-6 h-5 text-white" />
            </button>
          )}
        </div>
      </div>

      <div className="text-[12px] text-oya-red-50">{errorCode}</div>
    </div>
  );
};

export default TripCodeSearch;
