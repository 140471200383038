import React from "react";
import processing from "../../assets/img/pay_processing.gif";

type Props = {
  phone: any;
};

const PaymentProcessing = ({ phone }: Props) => {
  return (
    <div className="bg-white min-h-screen">
      <div className="w-full px-[15px] flex items-center mt-7 justify-center gap-4">
        <div className="header-text-3 text-center ">Payment processing</div>
        <button>
          <div className="text-oya-red-50">Cancel</div>
        </button>
      </div>

      <div className="min-h-full justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <img
            alt="processing"
            src={processing}
            className="mt-[8rem] w-[30%] h-auto"
          />
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="text-center mt-3 w-[80%]">
            Payment from <span className="text-[17px]">{phone}</span> is being
            processed. You will receive a prompt to enter your PIN to complete
            this transaction.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessing;
