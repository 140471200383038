import React, { useState, useEffect } from "react";
import BottomNav from "../components/BottomNav";
import { Helmet } from "react-helmet";
import TripSearch from "../components/TripSearch";
import TripCodeSearch from "../components/TripCodeSearch";
import { Carousel } from "antd";
import { ADS } from "../../helpers/constants";
import { Skeleton, Row, Col, Pagination } from "antd";
import Ads from "../components/Ads";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { Schedule } from "../../types";
import { RouteConstants } from "../../helpers/constants";
import { api } from "../../helpers/api";
import ReactGA from "react-ga";
import notFound from "../../assets/img/bx_error.png";
import CustomButton from "../../components/CustomBtn";
import MobileScheduleCard from "../components/MobileScheduleCard";
import ProfileDropdown from "../components/ProfileDropdown";
import { db } from "../../types/db";
import { getCookie } from "../../helpers/utils";
import { useMediaQuery } from "@mui/material";

const MobileHome = () => {
  const token = getCookie("oya_token");
  const [searchType, setSearchType] = useState<number>(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const isLarge = useMediaQuery("(min-width:1024px)");

  const navigate = useNavigate();

  const handleSearchTypeChange = (type: number) => {
    setSearchType(type);
  };

  const handleDotClicked = (index: number) => {
    setCurrentSlide(index);
  };

  const cleaDb = async () => {
    try {
      await db.seats.clear();
    } catch (error) {
      console.log(error);
    }
  };

  const [meta, setMeta] = useState({
    page: 0,
    limit: 12,
    offset: 0,
    total_pages: null,
    total: 0,
  });

  // const handleTripCode = useCallback(
  //   (value: string | undefined) => {
  //     setTripCode(value);
  //   },
  //   // eslint-disable-next-line
  //   [tripCode]
  // );

  const getRides = async () => {
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [`${startOfDay.toISOString()}`, `${endofDay.toISOString()}`],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    try {
      const response = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${meta.limit}&offset=${Number(meta.limit * pageIndex)}`
      );

      if (response.data?.payload?.items?.length > 0) {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        if (response.data?.payload?.total > meta?.limit) {
          setHasMore(true);
          setSchedules(response.data?.payload?.items);
        } else {
          setSchedules(response.data?.payload?.items);
        }
      } else {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        setHasMore(false);
        setSchedules(response.data?.payload?.items);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (page: number, pageSize: number) => {
    setPageIndex(page - 1);
    setMeta((prev) => ({
      ...prev,
      limit: pageSize,
    }));
  };

  useEffect(() => {
    if (!isLarge) {
      cleaDb();
      localStorage.removeItem("trd");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    getRides();
    // eslint-disable-next-line
  }, [pageIndex, meta.limit]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={"#B40303"}
        />
        <meta name="theme-color" content={"#fff"} />
      </Helmet>
      <div className="bg-[#fff] relative h-screen ">
        <div className="h-full w-full">
          <div className="top-0 fixed z-[100] w-full">
            <div className="relative w-full inline-flex flex-col ">
              <div className="bg-gradient-to-b from-oya-red-100 to-oya-secondary-red pb-[3rem] rounded-b-[12px]">
                <div className="w-full flex justify-between items-center text-white px-5 py-5">
                  <div className="text-lg font-bold">Home</div>
                  {token && (
                    <div className="flex gap-2 items-center">
                      {/* <button>
                        <img
                          style={{
                            width: 36,
                            height: 36,
                          }}
                          src={require("../../assets/img/bell.png")}
                          alt="bell"
                        />
                      </button> */}
                      <button onClick={() => navigate("/mobile-settings")}>
                        <ProfileDropdown />
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full flex gap-4 items-center  px-5 pb-5">
                  <button
                    className={`${
                      searchType === 1
                        ? "bg-white rounded-[8px] text-oya-red-100 shadow-xs font-semibold"
                        : " text-white border border-white rounded-[8px]"
                    } px-2 py-1`}
                    onClick={() => handleSearchTypeChange(1)}
                  >
                    <div className="text-[12px]">Find a trip</div>
                  </button>
                  <button
                    className={`${
                      searchType === 2
                        ? "bg-white rounded-[8px] text-oya-red-100 shadow-xs"
                        : " text-white border border-white rounded-[8px]"
                    } px-2 py-1`}
                    onClick={() => handleSearchTypeChange(2)}
                  >
                    <div className="text-[12px]">Use a trip code</div>
                  </button>
                </div>
              </div>

              <div className="rounded-[12px] bg-white ml-5 mr-5 justify-center items-center -translate-y-9 shadow-md">
                <div className="h-[72px] justify-center items-center flex flex-col">
                  {searchType === 1 ? <TripSearch /> : <TripCodeSearch />}
                </div>
              </div>
            </div>
          </div>
          {/* main content */}
          <div className="min-h-screen mt-[16rem]">
            <div className="h-full px-5">
              <Carousel
                autoplay
                effect="scrollx"
                speed={200}
                afterChange={(slide) => setCurrentSlide(slide)}
                pauseOnHover
                dots={false}
              >
                {ADS.map((banner) => (
                  <Ads key={banner.id} image={banner.image} />
                ))}
              </Carousel>
              <div className="flex gap-2 justify-center -translate-y-5">
                {ADS.map((_banner, index) => (
                  <span
                    className={`w-[10px] h-[10px] rounded-[50%] cursor-pointer ${
                      currentSlide === index ? "bg-oya-red-100" : "bg-white"
                    }`}
                    key={index}
                    onClick={() => handleDotClicked(index)}
                  ></span>
                ))}
              </div>
              <div className="text-lg font-semibold mt-3">
                Do more with Busride
              </div>
              <div className="flex items-center justify-between gap-5 flex-wrap mt-2">
                <button
                  className=" justify-center items-center flex flex-col"
                  onClick={() => navigate("/advance-ticket")}
                >
                  <div className="rounded-lg bg-oya-gray-50 p-2 w-[56] h-[56]">
                    <img
                      className="w-[36px] h-[36px]"
                      src={require("../../assets/img/schedule.png")}
                      alt="advance booking"
                    />
                  </div>
                  <div className="text-xs text-oya-gray font-medium mt-1 text-center">
                    Book advance
                  </div>
                </button>
                {/* <button
                  onClick={() => navigate("/parcel")}
                  className=" justify-center items-center flex flex-col"
                >
                  <div className="rounded-lg bg-oya-gray-50 p-2">
                    <img
                      className="w-[36px] h-[36px]"
                      src={require("../../assets/img/box.png")}
                      alt="parcel"
                    />
                  </div>
                  <div className="text-xs text-oya-gray font-medium mt-1 text-center">
                    Parcel
                  </div>
                </button> */}
                {/* <button className=" justify-center items-center flex flex-col">
                  <div className="rounded-lg bg-oya-gray-50 p-2">
                    <img
                      className="w-[36px] h-[36px]"
                      src={require("../../assets/img/emergency.png")}
                      alt="emergency"
                    />
                  </div>
                  <div className="text-xs text-oya-gray font-medium mt-1 text-center">
                    Emergency
                  </div>
                </button>
                <button className=" justify-center items-center flex flex-col">
                  <div className="rounded-lg bg-oya-gray-50 p-2">
                    <img
                      className="w-[36px] h-[36px]"
                      src={require("../../assets/img/explode.png")}
                      alt="Report"
                    />
                  </div>
                  <div className="text-xs text-oya-gray font-medium mt-1 text-center">
                    Report
                  </div>
                </button> */}
              </div>

              <div className="flex items-center justify-between mt-6">
                <div className="text-lg font-semibold">
                  Available buses for today
                </div>
                {schedules.length > 0 && (
                  <button
                    onClick={() => navigate("/trip-search?q=all")}
                    className="text-oya-red-100 font-semibold flex gap-2 items-center"
                  >
                    <div>View all</div>
                    <ChevronRightIcon className="w-5 h-5" />
                  </button>
                )}
              </div>

              {loading ? (
                <Row className="gap-3 mt-3">
                  <Col xs={24} md={12} lg={7}>
                    <div className="p-2 border shadow rounded-md">
                      <Skeleton paragraph active />
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={7}>
                    <div className="p-2 border shadow rounded-md">
                      <Skeleton paragraph active />
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={7}>
                    <div className="p-2 border shadow rounded-md">
                      <Skeleton paragraph active />
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  {schedules.length <= 0 ? (
                    <div className=" py-3">
                      <div className="flex flex-col justify-center items-center h-full">
                        <div className="border rounded-lg w-full h-[191px] flex flex-col justify-center items-center mt-7">
                          <img
                            className="w-[40px] h-[40px] mt-4"
                            src={notFound}
                            alt="404"
                          />
                          <div className="sm:text-xl text-center mt-11 text-gray-500">
                            Sorry, there are no available trips
                          </div>
                          <div className="mt-2 mb-4">
                            <CustomButton
                              onClick={() => {
                                navigate("/advance-ticket");
                              }}
                            >
                              Book for advance ticket
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-3">
                        {schedules.map((item, index) => (
                          <div className="w-full min-w-[220px]" key={index}>
                            <MobileScheduleCard item={item} />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}

              <div className="pt-10 w-full flex justify-end items-end">
                {hasMore && (
                  <div className="pagination w-full justify-end items-end">
                    <Pagination
                      onChange={(page, pageSize) =>
                        handlePagination(page, pageSize)
                      }
                      defaultPageSize={meta.limit}
                      total={meta.total}
                    />
                  </div>
                )}
              </div>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>

          {/* foot */}
          <div className="fixed w-full bottom-0">
            <BottomNav activeIndex={0} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileHome;
