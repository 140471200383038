import { Fragment, useState } from "react";
import BottomNav from "../components/BottomNav";
import {
  ChevronRightIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../components/LogoutModal";
import { getCookie } from "../../helpers/utils";
// import { Switch } from "@mui/material";
import DeleteModal from "../components/DeleteModal";

const Settings = () => {
  //const [isDarkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showDelete, setDelete] = useState(false);

  const token = getCookie("oya_token");

  //tab names
  const tabNames = [
    // {
    //   name: "Personal",
    //   icon: (
    //     <img
    //       src={require("../../assets/img/user.png")}
    //       className="h-6 w-6 "
    //       alt="Profile"
    //     />
    //   ),
    //   description: "See your personal settings",
    //   route: "/personal",
    // },
    {
      name: "App info",
      icon: (
        <img
          src={require("../../assets/img/info-circle.png")}
          className="h-6 w-6 "
          alt="info"
        />
      ),
      route: "/app-info",
      description: "View application information",
    },
  ];

  // const handleLogout = () => {
  //   StorageBox.clearStorage();
  //   window.location.reload();
  // };

  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-white relative">
        <div className="py-4 mx-3">
          <div className="header-text-3">Settings</div>
        </div>
        {/* head */}
        <div className="mt-5">
          <div className="h-full w-full">
            {token && (
              <button
                onClick={() => {
                  navigate("/personal");
                }}
                className="cursor-pointer p-5 border-b-[1px] border-oya-gray-100 flex justify-between items-center w-full"
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={require("../../assets/img/user.png")}
                    className="h-6 w-6 "
                    alt="Profile"
                  />
                  <div className="text-left">
                    <div className="text-[16px] text-left">Personal</div>
                    <div className="text-[12px]">
                      See your personal settings
                    </div>
                  </div>
                </div>
              </button>
            )}
            {tabNames.map((tab, i) => (
              <div
                key={i}
                className="cursor-pointer p-5 border-b-[1px] border-oya-gray-100 flex justify-between items-center w-full"
                onClick={() => navigate(tab.route)}
              >
                <div className="flex gap-4 items-center">
                  {tab?.icon}
                  <div>
                    <div className="text-[16px]">{tab?.name}</div>
                    <div className="text-[12px]">{tab?.description}</div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="w-6 h-6 text-black" />
                </div>
              </div>
            ))}
            {/* <div className=" p-5 border-b-[1px] border-oya-gray-100 flex justify-between items-center w-full">
              <div className="flex gap-4 items-center">
                <img
                  src={require("../../assets/img/sun.png")}
                  className="w-6 h-6"
                  alt="sun"
                />
                <div>
                  <div className="text-[16px]">Dark mode</div>
                  <div className="text-[12px]">Toggle dark mode on or off</div>
                </div>
              </div>
              <div>
                <Switch color="primary" />
              </div>
            </div> */}
            {token && (
              <button
                className="p-5 border-b-[1px] border-oya-gray-100 flex gap-4 items-center w-full"
                onClick={() => setDelete(true)}
              >
                <img
                  src={require("../../assets/img/trash.png")}
                  className="w-6 h-6"
                  alt="sun"
                />
                <div>
                  <div className="text-[16px] text-left">Delete Account</div>
                  <div className="text-[12px]">
                    Delete account and all data associated with it
                  </div>
                </div>
              </button>
            )}
            {token ? (
              <div
                className="cursor-pointer p-5 border-b-[1px] border-oya-gray-100 flex gap-4 items-center"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={require("../../assets/img/logout.png")}
                    className="w-6 h-6"
                    alt="sun"
                  />
                  <div>
                    <div className="text-[16px] text-red-500">Log out</div>
                    <div className="text-[12px]">
                      Remove this account from this device
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <button
                className=" p-5 border-b-[1px] border-oya-gray-100 flex gap-4 items-center w-full"
                onClick={() => {
                  navigate("/signin");
                }}
              >
                <div className="flex gap-4 items-center">
                  <ArrowRightCircleIcon className="w-6 h-6" />

                  <div>
                    <div className="text-[16px] text-red-500 text-left">
                      Log in
                    </div>
                    <div className="text-[12px]">Sign in into this device.</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>

        {/* foot */}
        <div className="fixed w-full bottom-0">
          <BottomNav activeIndex={1} />
        </div>

        <LogoutModal
          visible={open}
          handleClose={() => setOpen(false)}
          appref="mobile-settings"
        />
        <DeleteModal
          visible={showDelete}
          handleClose={() => {
            setDelete(false);
          }}
        />
      </div>
    </Fragment>
  );
};

export default Settings;
