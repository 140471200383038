import React, { useState, useEffect } from "react";
import logoImg from "../../assets/img/logo.png";
import { Avatar, Popover, Dropdown } from "antd";
import { getCookie } from "../../helpers/utils";
import type { MenuProps } from "antd";
import { logout } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { accounts } from "../../helpers/api";
import Signin from "../Auth/Signin";
import SignUp from "../Auth/Signup";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
// import { BellIcon } from '@heroicons/react/24/outline';
import { RouteConstants } from "../../helpers/constants";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/reducer";

type Props = {};

const Header = (props: Props) => {
  const [data, setData] = useState({
    name: "",
    image: "",
    phone: "",
  });
  const token = getCookie("oya_token");
  const user = getCookie("oya_user_info");
  const parsedUserObj = user ? JSON.parse(user) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const navigate = useNavigate();

  const isLarge = useMediaQuery("(min-width:1024px)");

  const items: MenuProps["items"] = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z"
            fill="#7C0000"
          />
        </svg>
      ),
      onClick: () => {
        // handleLogout();
        logout();
        // dispatch(logout());
      },
      label: (
        <button
          data-testid="headerLogout"
          className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
        >
          Logout
        </button>
      ),
      key: "1",
    },
  ];

  const getUserData = async () => {
    if (token && user) {
      setIsLoading(true);
      try {
        const res = await accounts.get(`${RouteConstants.PROFILE}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(res.data?.payload);
        dispatch(setUser(res.data?.payload));
        setIsLoading(false);
        //return res;
      } catch (e) {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (user && token) {
      getUserData();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="navbar z-[100] w-full sm:px-[8rem] px-[1rem] fixed">
      <Signin
        isOpen={openLogIn}
        handleClosed={() => setOpenLogIn(false)}
        openSignUp={() => setOpenRegister(true)}
      />

      <SignUp
        isOpen={openRegister}
        handleClosed={() => setOpenRegister(false)}
        openSignIn={() => setOpenLogIn(true)}
      />
      <div className="flex justify-between items-center h-[64px]">
        <button
          data-testid="headerBusRide"
          onClick={() => navigate("/")}
          className="flex gap-4 items-center"
        >
          <img width={30} src={logoImg} alt="brand" />
          <div className="sm:text-[20px] font-bold text-oya-gray">Bus Ride</div>
        </button>
        <div>
          {isLoading ? null : (
            <>
              {parsedUserObj ? (
                <>
                  {isLarge ? (
                    <div className="flex gap-3 items-center">
                      <div className="hover:bg-gray-50 hover:rounded-md hover:cursor-pointer p-2">
                        <Dropdown
                          trigger={["click"]}
                          menu={{ items }}
                          overlayStyle={{
                            boxShadow: "#1d2a9f 0px 1px 4px",
                            position: "relative",
                            width: 256,
                            borderRadius: 8,
                          }}
                          onOpenChange={() => setVisible(!visible)}
                          placement="bottomRight"
                          arrow
                        >
                          <div className="flex gap-2 items-center">
                            {data?.image ? (
                              <Avatar
                                alt="user"
                                size={"large"}
                                shape="square"
                                src={data?.image}
                              />
                            ) : (
                              <Avatar
                                size={"large"}
                                shape="square"
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_13_361)">
                                      <rect
                                        width="48"
                                        height="48"
                                        rx="12"
                                        fill="white"
                                      />
                                      <rect
                                        x="14"
                                        y="8"
                                        width="20"
                                        height="20"
                                        rx="10"
                                        fill="#484442"
                                      />
                                      <rect
                                        x="-12"
                                        y="32"
                                        width="72"
                                        height="72"
                                        rx="36"
                                        fill="#484442"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_13_361">
                                        <rect
                                          width="48"
                                          height="48"
                                          rx="12"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                }
                              />
                            )}
                            <div className="text-[19px]">
                              {parsedUserObj?.name}
                            </div>
                            <ChevronDownIcon className="w-[32px] h-[32px] text-oya-gray" />
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-3">
                      <div className="hover:bg-gray-50 hover:rounded-md hover:cursor-pointer p-2">
                        <Dropdown
                          trigger={["click"]}
                          menu={{ items }}
                          overlayStyle={{
                            boxShadow: "#1d2a9f 0px 1px 4px",
                            position: "relative",
                            width: 256,
                            borderRadius: 8,
                          }}
                          onOpenChange={() => setVisible(!visible)}
                          placement="bottomRight"
                          arrow
                        >
                          <div className="flex gap-2 items-center">
                            {data?.image ? (
                              <Avatar
                                alt="user"
                                size={"large"}
                                shape="square"
                                src={data?.image}
                              />
                            ) : (
                              <div className="rounded-sm">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="48"
                                  height="48"
                                  viewBox="0 0 48 48"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_13_361)">
                                    <rect
                                      width="48"
                                      height="48"
                                      rx="12"
                                      fill="white"
                                    />
                                    <rect
                                      x="14"
                                      y="8"
                                      width="20"
                                      height="20"
                                      rx="10"
                                      fill="#484442"
                                    />
                                    <rect
                                      x="-12"
                                      y="32"
                                      width="72"
                                      height="72"
                                      rx="36"
                                      fill="#484442"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13_361">
                                      <rect
                                        width="48"
                                        height="48"
                                        rx="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            )}
                            {/* <div>{parsedUserObj?.name}</div> */}
                            <Popover
                              placement="right"
                              content={() => (
                                <div className="text-[19px]">
                                  {parsedUserObj?.first_name}
                                </div>
                              )}
                            >
                              <ChevronDownIcon className="w-[32px] h-[32px] text-oya-gray" />
                            </Popover>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <button
                  data-testid="headerSignIn"
                  onClick={() => {
                    //logout();
                    setOpenLogIn(true);
                  }}
                  className="flex items-center gap-2"
                >
                  <div className="text-[20px] text-oya-red-100">Sign in</div>
                  {/* <div>
                    <ChevronDownIcon className='w-[32px] h-[32px] text-oya-gray' />
                  </div> */}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
