import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducer";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: userReducer,
});

export default store;
