import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

interface navProps {
  activeIndex: number;
}

const BottomNav = ({ activeIndex }: navProps) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);

  //get active tab on load
  useEffect(() => {
    setActiveMenu(activeIndex);
  }, [activeIndex]);

  const menuList = [
    {
      id: 1,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M12.5 18V15"
            stroke="#939291"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.57 2.81985L3.64002 8.36985C2.86002 8.98985 2.36002 10.2998 2.53002 11.2798L3.86002 19.2398C4.10002 20.6598 5.46002 21.8098 6.90002 21.8098H18.1C19.53 21.8098 20.9 20.6498 21.14 19.2398L22.47 11.2798C22.63 10.2998 22.13 8.98985 21.36 8.36985L14.43 2.82985C13.36 1.96985 11.63 1.96985 10.57 2.81985Z"
            stroke="#939291"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002Z"
            fill="white"
          />
          <path
            d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
            fill="#FF6200"
          />
        </svg>
      ),
      name: "Home",
      route: "/",
    },
    // {
    //   id: 2,
    //   icon: (
    //     <img
    //       src={require("../../assets/img/gift.png")}
    //       alt="Parcel"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   activeIcon: (
    //     <img
    //       src={require("../../assets/img/gift_active.png")}
    //       alt="Parcel"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   name: "Parcel",
    //   route: "/parcel",
    // },
    // {
    //   id: 3,
    //   activeIcon: (
    //     <img
    //       src={require("../../assets/img/activity_active.png")}
    //       alt="Activities"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   icon: (
    //     <img
    //       src={require("../../assets/img/activity.png")}
    //       alt="Activies"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   name: "Activies",
    //   route: "/activities",
    // },
    {
      id: 4,
      activeIcon: <Cog6ToothIcon className="w-7 h-7 text-white" />,

      icon: <Cog6ToothIcon className="w-7 h-7 text-[#939291]" />,
      route: "/mobile-settings",
      name: "Settings",
    },
  ];

  const handleNavigation = (selected: any, index: number) => {
    setActiveMenu(index);
    navigate(selected.route);
  };

  return (
    <Fragment>
      <div className="h-[80px] nav-bar relative">
        {menuList.map((menu: any, i: number) => (
          <button
            key={i}
            className={`${
              activeMenu === i
                ? "text-white bg-oya-red-100 h-12 w-12"
                : "text-neutral-300"
            }  rounded-md flex justify-center items-center mr-3`}
            onClick={() => handleNavigation(menu, i)}
          >
            <div className="flex flex-col justify-center items-center">
              {activeMenu === i ? (
                <div>{menu.activeIcon}</div>
              ) : (
                <div className="flex flex-col justify-center items-center text-[10px] text-neutral-400">
                  <div>{menu.icon}</div>
                  <div className="pt-1">{menu.name}</div>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
    </Fragment>
  );
};

export default BottomNav;
