import React from "react";
import processing from "../../assets/img/pay_processing.gif";

const StillProcessing = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="w-full px-[15px] flex items-center mt-7 justify-center gap-4">
        <div className="header-text-3 text-center ">Payment processing</div>
        <button>
          <div className="text-oya-red-50">Cancel</div>
        </button>
      </div>

      <div className="min-h-full justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <img
            alt="processing"
            src={processing}
            className="mt-[8rem] w-[30%] h-auto"
          />
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="text-center mt-3 w-[80%]">
            Payment is still processing. You will be notified once transaction
            is successful.
          </div>
          <div>
            Call <span className="text-oya-red-50">03033456543</span> for
            support.
          </div>

          <button
            onClick={() => {}}
            className="bg-oya-red-100 mt-6 rounded-lg w-full h-[42px] flex justify-center items-center text-white"
          >
            <div className="text-[16px]">Dismiss</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StillProcessing;
