import { Checkbox, Modal } from "antd";
import React, { Fragment, useState } from "react";
import CustomButton from "../CustomBtn";

type LuggageType = {
    code: string;
    name: string;
    description: string;
};

type ModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleSelectedStop: (type: any) => void;
    luggages: LuggageType[];
};

const LuggageModal = ({
    isOpen,
    handleClose,
    handleSelectedStop,
    luggages,
}: ModalProps) => {
    const [error, setError] = useState<boolean>(false);
    const lsArr: any = localStorage.getItem("lsar");
    const lsObj = JSON.parse(lsArr);

    const [smLuggage, setSmLuggage] = useState(
        lsObj?.map((item: LuggageType) => ({
            code: item.code,
            description: item.description,
            name: item.name,
            quantity: 0,
        }))
    );
    // const [mdLuggage, setMdLuggage] = useState(0);
    // const [lgLuggage, setLgLuggage] = useState(0);

    // const [lugggaeChecked, setLuggageChecked] = useState<string | null>("");

    // const [smChecked, setSmChecked] = useState(false);
    // const [mdChecked, setMdChecked] = useState(false);
    // const [lgChecked, setLgChecked] = useState(false);

    //console.log(luggages.map(() => 0))

    const increaseQuantity = (index: number) => {
        let newItems = [...smLuggage];
        newItems[index].quantity = newItems[index]?.quantity + 1;
        setSmLuggage(newItems);

        // setSmLuggage((prevItems: any) => {
        //   const newItems = [...prevItems];
        //   newItems[index].quantity = 1 + newItems[index].quantity;
        //   return newItems;
        // });
    };

    const decreaseQuantity = (index: number) => {
        let newItems = [...smLuggage];
        if (newItems[index].quantity > 0) {
            newItems[index].quantity = newItems[index]?.quantity - 1;
            setSmLuggage(newItems);
        }
    };

    return (
        <Fragment>
            <Modal
                open={isOpen}
                footer={null}
                title="Luggage"
                onCancel={handleClose}
            >
                <div className="mb-8 mt-5">
                    <div className="luggage-container">
                        {lsObj?.length > 0 ? (
                            <>
                                {smLuggage?.map((item: any, index: number) => (
                                    <div
                                        className={`flex items-center justify-between  rounded-md p-4 mb-1 ${
                                            item?.quantity > 0
                                                ? "border-oya-red-100 border"
                                                : ""
                                        }`}
                                        key={index}
                                    >
                                        <div className="flex-[0.7] flex items-start gap-3">
                                            <Checkbox
                                                checked={
                                                    item?.quantity > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        let newItems = [
                                                            ...smLuggage,
                                                        ];
                                                        newItems[
                                                            index
                                                        ].quantity = 1;
                                                        setSmLuggage(newItems);
                                                    } else {
                                                        let newItems = [
                                                            ...smLuggage,
                                                        ];
                                                        newItems[
                                                            index
                                                        ].quantity = 0;
                                                        setSmLuggage(newItems);
                                                    }
                                                }}
                                            />
                                            <div>
                                                <div className="text-[16px]">
                                                    {item?.name}
                                                </div>
                                                <div className="text-[14px]">
                                                    {item?.description}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-[0.3]">
                                            <div className="flex items-center gap-4">
                                                <button
                                                    data-testid="decreaseQuantity"
                                                    onClick={() => {
                                                        decreaseQuantity(index);
                                                        // const newLuggage = [...smLuggage];
                                                        // if (newLuggage[index] > 0) {
                                                        //   newLuggage[index]--;
                                                        //   setSmLuggage(newLuggage);
                                                        // }
                                                    }}
                                                    className="rounded-btn-sm"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332C11.172 10.8332 8.31424 10.8332 15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C8.31425 9.1665 11.172 9.1665 4.16671 9.1665Z"
                                                            fill="#939291"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className="text-[18px] font-semibold">
                                                    {item.quantity}
                                                </div>
                                                <button
                                                    data-testid="increaseQuantity"
                                                    onClick={() => {
                                                        // const newLuggage = [...smLuggage];
                                                        // console.log(newLuggage);
                                                        // newLuggage[index]++;
                                                        // setSmLuggage(newLuggage);
                                                        increaseQuantity(index);
                                                    }}
                                                    className="rounded-btn-sm"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M10.8334 4.16683C10.8334 3.70659 10.4603 3.3335 10 3.3335C9.5398 3.3335 9.16671 3.70659 9.16671 4.16683V9.16683H4.16671C3.70647 9.16683 3.33337 9.53992 3.33337 10.0002C3.33337 10.4604 3.70647 10.8335 4.16671 10.8335H9.16671V15.8335C9.16671 16.2937 9.5398 16.6668 10 16.6668C10.4603 16.6668 10.8334 16.2937 10.8334 15.8335V10.8335H15.8334C16.2936 10.8335 16.6667 10.4604 16.6667 10.0002C16.6667 9.53993 16.2936 9.16683 15.8334 9.16683H10.8334V4.16683Z"
                                                            fill="#B40303"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div>No luggage sizes</div>
                        )}
                    </div>

                    {error && (
                        <div className="mt-1 text-oya-red-100 ml-3">
                            Please add a luggage to continue
                        </div>
                    )}
                </div>

                <div className="w-full">
                    <CustomButton
                        data-testid="addLuggage"
                        onClick={() => {
                            let lugArr: any = [];
                            smLuggage.forEach((item: any) => {
                                if (item?.quantity > 0) {
                                    lugArr.push(item);

                                    setError(false);
                                }
                            });

                            if (lugArr.length === 0) {
                                setError(true);
                            } else {
                                setError(false);
                                //console.log(lugArr);
                                localStorage.setItem(
                                    "ulgs",
                                    JSON.stringify(lugArr)
                                );

                                handleSelectedStop(lugArr);
                            }
                        }}
                        width="w-full"
                    >
                        Add luggage
                    </CustomButton>
                </div>
            </Modal>
        </Fragment>
    );
};

export default LuggageModal;
