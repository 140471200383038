import { useState, useEffect } from "react";
import moment from "moment";
import { CalendarMonth } from "@mui/icons-material";
// import CustomButton from '../CustomBtn';
import { BusFeature, Schedule } from "../../types";
import { useNavigate } from "react-router-dom";
import Prompt from "../Auth/Prompt";
import { getCookie } from "../../helpers/utils";
import ReactGA from "react-ga";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";

const ScheduleCard = ({ item }: { item: Schedule }) => {
    const [authenticated, setNoAuth] = useState(false);

    const [features, setFeatures] = useState<BusFeature[]>([]);

    const token = getCookie("oya_token");
    const user = getCookie("oya_usr");

    const navigate = useNavigate();

    const getBusFeatures = async () => {
        try {
            const { data } = await api.get(
                `${RouteConstants.BUS_FEATURES}/${item?.bus?.id}/features`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setFeatures(data?.payload?.items);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBusFeatures();

        // eslint-disable-next-line
    }, []);

    return (
        <div className="schedule-card mb-3 items-start justify-start text-left min-h-[400]: max-h-[400]">
            <Prompt
                isOpen={authenticated}
                handleClosed={() => setNoAuth(false)}
                scheduleId={item?.id}
            />
            <div className="flex items-center justify-between sm:gap-[30px] p-4 ">
                <div className="flex items-center gap-1 h-8">
                    <CalendarMonth sx={{ color: "red" }} />
                    <div>{moment(item?.departures_at).format("LL")}</div>
                </div>
                {item?.premium && (
                    // <div className='premium-container text-oya-red-100 font-semibold'>
                    //   Premium
                    // </div>
                    <div className="bg-[#FFF7D6] p-2 rounded-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M1.33334 10.1933V3.80667C1.33334 2.92 1.84668 2.70667 2.47334 3.33333L4.20001 5.06C4.46001 5.32 4.88668 5.32 5.14001 5.06L7.52668 2.66667C7.78668 2.40667 8.21334 2.40667 8.46668 2.66667L10.86 5.06C11.12 5.32 11.5467 5.32 11.8 5.06L13.5267 3.33333C14.1533 2.70667 14.6667 2.92 14.6667 3.80667V10.2C14.6667 12.2 13.3333 13.5333 11.3333 13.5333H4.66668C2.82668 13.5267 1.33334 12.0333 1.33334 10.1933Z"
                                fill="#F5CD47"
                            />
                        </svg>
                    </div>
                )}
            </div>

            <div className="w-full border-b border-[#000] h-[1px]" />

            <div className="p-4 flex justify-between sm:gap-[30px]">
                <div className="text-[10px] text-oya-gray-200 h-4">
                    {item?.branch?.name}
                </div>
                {item?.premium && (
                    <div>
                        {features.length > 0 && (
                            <div className="flex items-center gap-2">
                                {features.map((feature) => (
                                    <>
                                        {feature?.feature?.name ===
                                            "USB Port" && (
                                            <img
                                                alt="usb port"
                                                src={require("../../assets/plug.png")}
                                                className="w-4 h-4"
                                            />
                                        )}
                                        {feature?.feature?.name === "Wifi" && (
                                            <img
                                                alt="usb port"
                                                src={require("../../assets/wifi.png")}
                                                className="w-4 h-4"
                                            />
                                        )}
                                        {feature?.feature?.name ===
                                            "Video & Movie" && (
                                            <img
                                                alt="usb port"
                                                src={require("../../assets/video-square.png")}
                                                className="w-4 h-4"
                                            />
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="p-4 text-[14px]">
                <div className="flex gap-1 items-center">
                    <div className="w-[20px] h-[20px] rounded-full border-[1.5px] border-oya-gray-200 flex items-center justify-center">
                        <div className="w-[10px] h-[10px] bg-oya-gray rounded-full" />
                    </div>
                    <div className="font-semibold">
                        {item?.route?.from?.name}
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center w-[20px]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="41"
                            viewBox="0 0 2 41"
                            fill="none"
                        >
                            <path
                                d="M1 1L1 40"
                                stroke="#D4D3D3"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-dasharray="1 7"
                            />
                        </svg>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex gap-1 items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M9.99999 11.1917C11.4359 11.1917 12.6 10.0276 12.6 8.59167C12.6 7.15573 11.4359 5.99167 9.99999 5.99167C8.56405 5.99167 7.39999 7.15573 7.39999 8.59167C7.39999 10.0276 8.56405 11.1917 9.99999 11.1917Z"
                                stroke="#FF5A55"
                                stroke-width="1.5"
                            />
                            <path
                                d="M3.01667 7.075C4.65834 -0.141667 15.35 -0.133334 16.9833 7.08333C17.9417 11.3167 15.3083 14.9 13 17.1167C11.325 18.7333 8.67501 18.7333 6.99167 17.1167C4.69167 14.9 2.05834 11.3083 3.01667 7.075Z"
                                stroke="#FF5A55"
                                stroke-width="1.5"
                            />
                        </svg>
                        <div className="font-semibold">
                            {item?.route?.to?.name}
                        </div>
                    </div>
                    <div>
                        <div className="font-semibold text-[16px]">
                            {Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: item?.price?.currency,
                            }).format(parseFloat(item?.price?.amount))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex px-4 pb-6 text-[10px]">
                <div className="text-oya-red-50 flex-[0.6]">
                    {item?.bus?.type?.name}{" "}
                    {item?.loading_point?.label
                        ? ` (${item?.loading_point?.label})`
                        : ""}
                </div>
                <div className="flex flex-[0.4] gap-1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                    >
                        <rect
                            y="1.94004"
                            width="1.16667"
                            height="8.26459"
                            rx="0.583333"
                            fill="#FF8380"
                        />
                        <rect
                            x="10.8333"
                            y="1.94004"
                            width="1.16667"
                            height="8.26459"
                            rx="0.583333"
                            fill="#FF8380"
                        />
                        <path
                            d="M2.33333 2.5782C2.33333 1.55692 3.10263 0.690626 4.12155 0.621261C5.43851 0.531606 6.554 0.534738 7.86999 0.624399C8.89231 0.694053 9.66667 1.56159 9.66667 2.58629V6.43696C9.66667 7.38095 9.00812 8.21162 8.07343 8.34384C6.64375 8.5461 5.44301 8.53633 3.96045 8.33136C3.01151 8.20016 2.33333 7.36392 2.33333 6.40596V2.5782Z"
                            fill="#FF8380"
                        />
                        <path
                            d="M2.33333 10.2034C2.33333 9.54004 2.96616 9.0641 3.61457 9.20396C5.35157 9.57859 6.67783 9.57088 8.38513 9.20262C9.03403 9.06265 9.66667 9.53927 9.66667 10.2031C9.66667 10.6838 9.32561 11.0994 8.85154 11.1793C6.77332 11.5293 5.30357 11.5369 3.14827 11.1777C2.67442 11.0987 2.33333 10.6837 2.33333 10.2034Z"
                            fill="#FF8380"
                        />
                    </svg>
                    <div className="text-[#FF8380]">
                        {item?.passenger_capacity - item?.onboard_passengers} of{" "}
                        {item?.passenger_capacity} seats available
                    </div>
                </div>
                {/* <div>Reg No. {item?.bus?.reg_number}</div> */}
            </div>

            <div className="w-full h-[1px] bg-oya-gray-100" />
            <div className="py-4">
                <div className="mx-4">
                    <button
                        data-testid="bookTrip"
                        className="book-trip-btn"
                        onClick={() => {
                            ReactGA.event({
                                category: `${process.env.NODE_ENV} Buy ticket`,
                                action: "View trip",
                                label: "Book trip",
                            });
                            if (token && user) {
                                navigate(`/trip/${item?.id}`);
                            } else {
                                setNoAuth(true);
                            }
                        }}
                    >
                        <div>Book Trip</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ScheduleCard;
