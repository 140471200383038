import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import CustomButton from "../CustomBtn";
import PhoneInput, { Value } from "react-phone-number-input";

import EmergencyContact from "./EmergencyContact";

const SignUp = ({
    isOpen,
    handleClosed,
    openSignIn,
    schedule,
}: {
    isOpen: boolean;
    handleClosed: () => void;
    openSignIn: () => void;
    schedule?: number;
}) => {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState<Value | undefined>("");
    const [emergencyContact, openEmergencyContact] = useState(false);

    const value = form.getFieldsValue();

    const validatePin = (e: any) => {
        const regex = /[^0-9]/gi;
        form.setFieldsValue({
            [e.target.id]: e.target.value.replace(regex, ""),
        });
    };

    return (
        <Modal footer={null} open={isOpen} onCancel={handleClosed}>
            <EmergencyContact
                isOpen={emergencyContact}
                handleClosed={() => openEmergencyContact(false)}
                closeSignUp={() => handleClosed()}
                values={{
                    ...value,
                    phone,
                }}
                schedule={schedule}
            />

            <div>
                <h4 className="text-lg md:text-[24px] font-bold">Sign Up</h4>
                <div className=" font-medium md:text-lg">
                    Create an account with us!
                </div>
            </div>

            <Form
                form={form}
                layout="vertical"
                className="w-full pt-10"
                autoComplete="false"
            >
                <div className="pt-1 w-full items-center flex-wrap">
                    <Form.Item
                        colon={false}
                        label={
                            <label
                                htmlFor="first_name"
                                className="block capitalize text-sm text-gray-601"
                            >
                                First name
                            </label>
                        }
                        className="w-full  mb-2 pr-3"
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                            {
                                validator: (_, value) =>
                                    !value?.includes(" ")
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error("No spaces allowed")
                                          ),
                            },
                        ]}
                    >
                        <Input
                            data-testid="register-first-name-input"
                            autoComplete="off"
                            size="large"
                            className="w-full px-4 bg-white border border-gray-200 outline-none rounded-md h-10"
                            disabled={false}
                        />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={
                            <label
                                htmlFor="last_name"
                                className="block capitalize text-sm text-gray-601"
                            >
                                Last name
                            </label>
                        }
                        className="w-full mb-2 pr-3"
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                            {
                                validator: (_, value) =>
                                    !value?.includes(" ")
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error("No spaces allowed")
                                          ),
                            },
                        ]}
                    >
                        <Input
                            data-testid="register-last-name-input"
                            autoComplete="off"
                            size="large"
                            className="w-full px-4 bg-white border border-gray-200 outline-none rounded-md h-10"
                            disabled={false}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    colon={false}
                    label={
                        <div className="text-gray-500 text-sm">
                            Mobile number
                        </div>
                    }
                    className="w-full mb-6"
                    name="phone"
                    rules={[
                        { required: true, message: "This field is required" },
                    ]}
                >
                    <PhoneInput
                        name="phone"
                        className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                        defaultCountry="GH"
                        onChange={(e) => {
                            setPhone(e);
                        }}
                    />
                </Form.Item>
                <div className="w-full">
                    <Form.Item
                        name="pin1"
                        colon={false}
                        label={
                            <label
                                htmlFor="pin1"
                                className="block text-sm text-gray-601"
                            >
                                PIN
                            </label>
                        }
                        className="w-full mb-2 pr-3"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <Input.Password
                            name="pin1"
                            type="password"
                            autoComplete="off"
                            size="large"
                            className="w-full px-4 mt-2 mb-2 border border-gray-200 rounded-md outline-none h-10"
                            maxLength={4}
                            minLength={4}
                            data-testid="register-pin-input"
                            onChange={validatePin}
                        />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={
                            <label
                                htmlFor="new_pin"
                                className="block text-sm text-gray-601"
                            >
                                Confirm PIN
                            </label>
                        }
                        name="pin2"
                        className="w-full mb-2 pr-3"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <Input.Password
                            type="password"
                            autoComplete="off"
                            name="pin2"
                            size="large"
                            className="w-full px-4 mt-2 mb-2 border border-gray-200 rounded-md outline-none h-10"
                            maxLength={4}
                            minLength={4}
                            data-testid="register-confirm-pin-input"
                            onChange={validatePin}
                        />
                    </Form.Item>
                </div>

                <div>
                    <CustomButton
                        data-testid="proceed"
                        width="w-full"
                        onClick={() => openEmergencyContact(true)}
                    >
                        Proceed
                    </CustomButton>
                    {/* <CustomButton
            onClick={() => {
              handleClosed();
            }}
            variant='secondary'
          >
            Cancel
          </CustomButton> */}
                </div>

                <div className="mt-4 font-light">
                    Already have an account?{" "}
                    <button
                        data-testid="login"
                        onClick={() => {
                            handleClosed();
                            openSignIn();
                        }}
                        type="button"
                        className="text-oya-red-100 font-semibold"
                    >
                        Login
                    </button>
                </div>
            </Form>
        </Modal>
    );
};

export default SignUp;
