import React from "react";
import ReactDOM from "react-dom/client";
// import * as Sentry from "@sentry/react";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from "react-router-dom";

// Sentry.init({
//   dsn: "https://836c7aac1da08f2dbcd4e9d92af6c9fc@sentry.kostkonsult.com/3",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes,
//     }),
//     Sentry.replayIntegration({
//       // Additional SDK configuration goes in here, for example:
//       maskAllText: false,
//       blockAllMedia: false,
//       maskAllInputs: false,
//     }),
//     Sentry.browserProfilingIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
