import React from "react";

type Props = {
  image: string;
};
const Ads = ({ image }: Props) => {
  return (
    <div className="w-full flex flex-col items-center">
      <img src={image} className="w-full h-auto rounded-lg" alt="banner" />
    </div>
  );
};

export default Ads;
