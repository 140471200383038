import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { IPopularRoute, Schedule } from "../../types";
import { Skeleton, Row, Col, Pagination, DatePicker } from "antd";
import CustomButton from "../../components/CustomBtn";
import MobileScheduleCard from "../components/MobileScheduleCard";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import notFound from "../../assets/img/bx_error.png";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { Option } from "../../types";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { disabledDate } from "../../helpers/utils";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import FilterSchedule from "../components/FilterSchedules";
import SortSchedules from "../components/SortSchedules";

dayjs.extend(weekday);
dayjs.extend(localeData);

type SORT = "desc" | "asc";

const FindTrip = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [date, setDate] = useState<any>(dayjs());
  const [openFilter, setOpenFilter] = useState(false);
  const [openSorting, setOpenSorting] = useState(false);
  const [busType, setBusType] = useState<any>([]);
  const [isPremium, setIsPremium] = useState<any>();
  const [sortType, setSortType] = useState<SORT>("asc");
  const [loadingRoutes, setLoadingRoutes] = useState(false);
  const [popularRoutes, setPopularRoutes] = useState<IPopularRoute[]>([]);

  const [meta, setMeta] = useState({
    page: 0,
    limit: 12,
    offset: 0,
    total_pages: null,
    total: 0,
  });

  const navigate = useNavigate();

  const [pickup, setPickup] = useState<any>();
  const [destination, setDestination] = useState<any>();

  const loadFroms = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "from.name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const { data } = await api.get(
          `${RouteConstants.ROUTES}?filters=${JSON.stringify(filterData)}`
        );

        const uniqueFromIds = new Set();
        const filteredFromData = data?.payload?.items.filter((item: any) => {
          if (!uniqueFromIds.has(item?.from?.id)) {
            uniqueFromIds.add(item?.from?.id);
            return true;
          }
          return false;
        });

        const searchOptions = filteredFromData?.map((item: any) => ({
          label: item?.from?.name,
          value: item?.from?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const loadTos = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "to.name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const { data } = await api.get(
          `${RouteConstants.ROUTES}?filters=${JSON.stringify(filterData)}`
        );

        const uniqueFromIds = new Set();
        const filteredFromData = data?.payload?.items.filter((item: any) => {
          if (!uniqueFromIds.has(item?.to?.id)) {
            uniqueFromIds.add(item?.to?.id);
            return true;
          }
          return false;
        });

        const searchOptions = filteredFromData?.map((item: any) => ({
          label: item?.to?.name,
          value: item?.to?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const getRides = async (destinationId?: number, datePicked?: any) => {
    //console.log(dayjs(datePicked).format("YYYY-MM-DD"));
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let localStartDate: any = datePicked
      ? dayjs(datePicked).format("YYYY-MM-DD")
      : dayjs(date).format("YYYY-MM-DD");

    let localEndDate: any = datePicked
      ? dayjs(datePicked).format("YYYY-MM-DD")
      : dayjs(date).format("YYYY-MM-DD");

    localStartDate = dayjs(localStartDate).hour(0).minute(0).second(0);
    localEndDate = dayjs(localEndDate).hour(23).minute(59).second(59);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [localStartDate.toISOString(), localEndDate.toISOString()],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    if (busType && busType.length > 0) {
      //console.log(busType);
      let bus_types = busType?.map((item: any) => `${item?.value}`);
      defaultFilter.push({
        f: "bus_type.id",
        o: "in",
        p: bus_types,
      });
    }

    if (isPremium === false || isPremium === true) {
      defaultFilter.push({
        f: "premium",
        o: "=",
        p: [isPremium],
      });
    }

    if (pickup && destination) {
      defaultFilter.push(
        {
          f: "route.from.id",
          o: "=",
          p: [pickup],
          r: "AND",
        },
        {
          f: "route.to.id",
          o: "=",
          p: [destination],
        }
      );
    }

    try {
      const response = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${meta.limit}&offset=${Number(
          meta.limit * pageIndex
        )}&sorting=price:${sortType}`
      );

      if (response.data?.payload?.items?.length > 0) {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        if (response.data?.payload?.total > meta?.limit) {
          setHasMore(true);
          setSchedules(response.data?.payload?.items);
        } else {
          setSchedules(response.data?.payload?.items);
        }
      } else {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        setHasMore(false);
        setSchedules(response.data?.payload?.items);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const getPopularRoutes = async () => {
    setLoadingRoutes(true);
    try {
      const { data } = await api.get(RouteConstants.POPULAR_ROUTES);
      setPopularRoutes(data?.payload?.items);
      setLoadingRoutes(false);
    } catch (error) {
      setLoadingRoutes(false);
    }
  };

  const handlePagination = (page: number, pageSize: number) => {
    setPageIndex(page - 1);
    setMeta((prev) => ({
      ...prev,
      limit: pageSize,
    }));
  };

  useEffect(() => {
    getPopularRoutes();
  }, []);

  useEffect(() => {
    if (destination) {
      getRides();
    }

    // eslint-disable-next-line
  }, [pageIndex, meta.limit, busType, isPremium, sortType, destination]);

  return (
    <div className="h-full overflow-y-auto">
      <div className="h-[44px] fixed w-full top-0 bg-white">
        <div className="flex mx-[15px] mt-3 items-center justify-center">
          <div
            className={`${
              schedules.length > 0
                ? "flex-[0.2]"
                : `${
                    typeof isPremium === "boolean" ? "flex-[0.2]" : "flex-[0.4]"
                  }`
            }`}
          >
            <button onClick={() => navigate(-1)}>
              <ChevronLeftIcon className="w-5 h-5 text-black" />
            </button>
          </div>
          <div
            className={`${
              schedules.length > 0
                ? "flex-[0.8] justify-between"
                : `${
                    typeof isPremium === "boolean" || busType !== undefined
                      ? "flex-[0.8] justify-between"
                      : "flex-[0.6] justify-start"
                  }`
            } flex items-center text-oya-gray font-semibold text-lg`}
          >
            <div>Find a trip</div>
            {destination && (
              <div className="flex gap-3 items-center">
                <button
                  onClick={() => setOpenSorting(true)}
                  className="flex gap-1 items-center text-oya-red-100"
                >
                  <img
                    className="w-5 h-5"
                    src={require("../../assets/img/sort.png")}
                    alt="Sort"
                  />
                  <div className="text-sm">Sort</div>
                </button>
                <button
                  onClick={() => setOpenFilter(true)}
                  className="flex gap-1 items-center text-oya-red-100"
                >
                  <img
                    className="w-4 h-4"
                    src={require("../../assets/img/filter.png")}
                    alt="Sort"
                  />
                  <div className="text-sm">Filter</div>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full bg-white p-3 rounded-xl pt-10 shadow-lg">
          <div className="border p-1 rounded-xl flex justify-center items-center w-full mb-2 flex-[1]">
            <div className="ml-1">
              <div className="w-[24px] h-[24px] rounded-full border-[1.5px] border-oya-gray-200 flex items-center justify-center">
                <div className="w-[12px] h-[12px] bg-oya-gray rounded-full" />
              </div>
            </div>
            <AsyncSelect
              //defaultOptions={allPickups}
              placeholder="Travelling from"
              loadOptions={(inputValue, callback) => {
                loadFroms(inputValue, callback);
              }}
              onChange={(e: any) => {
                setPickup(e?.value);
                if (!e) {
                  //handleSearch(undefined, destination);
                }
              }}
              styles={{
                container: (base, props) => ({
                  ...base,
                  flex: "1",
                }),
                control(base, props) {
                  return {
                    ":active": {
                      borderColor: "#ccc",
                    },
                    display: "flex",
                  };
                },
              }}
              isClearable
            />
          </div>
          <div className="border p-1 rounded-xl flex justify-center items-center w-full mb-2 flex-[1]">
            <div className="ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                  fill="#FF5A55"
                />
                <path
                  d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z"
                  fill="#FF5A55"
                />
              </svg>
            </div>
            <AsyncSelect
              placeholder="Travelling to"
              loadOptions={(inputValue, callback) => {
                loadTos(inputValue, callback);
              }}
              styles={{
                container: (base, props) => ({
                  ...base,
                  flex: "1",
                }),
                control(base, props) {
                  return {
                    ":active": {
                      borderColor: "#ccc",
                    },
                    display: "flex",
                  };
                },
              }}
              onChange={(e: any) => {
                setDestination(e?.value);
              }}
              isClearable
            />
          </div>

          <div className="border p-1 rounded-xl flex justify-center items-center w-full mb-2 flex-[1]">
            <div className="ml-1">
              <CalendarDaysIcon className="w-6 h-6 text-oya-red-50" />
            </div>
            <DatePicker
              disabledDate={disabledDate}
              value={date}
              format={"DD/MM/YYYY"}
              onChange={(date, dateString) => {
                setDate(date);
                getRides(undefined, date);
              }}
              bordered={false}
              suffixIcon={null}
              className={"w-full"}
            />
          </div>
        </div>
      </div>

      <div className="mx-[15px] mt-[18rem]">
        {destination ? (
          <div>
            <div className="font-semibold text-[14px] mb-3 mt-5">
              Select a trip
            </div>
            {loading ? (
              <Row className="gap-3 mt-3">
                <Col xs={24} md={12} lg={7}>
                  <div className="p-2 border shadow rounded-md">
                    <Skeleton paragraph active />
                  </div>
                </Col>
                <Col xs={24} md={12} lg={7}>
                  <div className="p-2 border shadow rounded-md">
                    <Skeleton paragraph active />
                  </div>
                </Col>
                <Col xs={24} md={12} lg={7}>
                  <div className="p-2 border shadow rounded-md">
                    <Skeleton paragraph active />
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                {schedules.length <= 0 ? (
                  <div className=" py-3">
                    <div className="flex flex-col justify-center items-center h-full">
                      <div className="border rounded-lg w-full h-[191px] flex flex-col justify-center items-center">
                        <img
                          className="w-[40px] h-[40px] mt-4"
                          src={notFound}
                          alt="404"
                        />
                        <div className="sm:text-xl text-center mt-8 text-gray-500">
                          Sorry, there are no available trips
                        </div>
                        <div className="mt-2 mb-4">
                          <CustomButton
                            onClick={() => {
                              // setBookAdvance(true);
                              // setFindTripActive(false);
                              // setTripCodeActive(false);
                              // if (!token) {
                              //   openAuthModal(true);
                              // }
                              navigate("/advance-ticket");
                            }}
                          >
                            Book for advance ticket
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-3">
                      {schedules.map((item, index) => (
                        <div className="w-full min-w-[220px]" key={index}>
                          <MobileScheduleCard item={item} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="pt-10 w-full flex justify-end items-end">
              {hasMore && (
                <div className="pagination w-full justify-end items-end">
                  <Pagination
                    onChange={(page, pageSize) =>
                      handlePagination(page, pageSize)
                    }
                    defaultPageSize={meta.limit}
                    total={meta.total}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="font-semibold text-[14px] mb-3 mt-5">
              Popular routes
            </div>
            {loadingRoutes ? (
              <div className="animate-pulse w-12 h-6 rounded-sm bg-[#F5F5F5]" />
            ) : (
              <div>
                {popularRoutes.length > 0 && (
                  <div className="flex items-center gap-2 flex-wrap">
                    {popularRoutes.map((item) => (
                      <button
                        onClick={() => {
                          setPickup(item?.from?.id);
                          setDestination(item?.to?.id);
                        }}
                        key={item?.id}
                      >
                        <div className="bg-[#F5F5F5] rounded-sm p-1">
                          {item?.from?.name} to {item?.to?.name}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <FilterSchedule
        isOpen={openFilter}
        handleOpenDrawer={() => setOpenFilter(false)}
        handleReset={() => {
          setIsPremium(undefined);
          setBusType(undefined);
        }}
        applyFilter={(bus_type, comfort_type) => {
          if (bus_type) {
            setBusType(bus_type);
          }

          if (comfort_type) {
            //console.log(comfort_type);
            if (comfort_type === "PREM") {
              setIsPremium(true);
            } else {
              setIsPremium(false);
            }
          }
        }}
      />
      <SortSchedules
        isOpen={openSorting}
        handleClose={() => setOpenSorting(false)}
        sorting={sortType}
        handleSortChange={(value: SORT) => setSortType(value)}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default FindTrip;
