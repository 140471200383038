import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Schedule } from "../../types";
import { Skeleton, Row, Col, Pagination } from "antd";
import CustomButton from "../../components/CustomBtn";
import MobileScheduleCard from "../components/MobileScheduleCard";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import notFound from "../../assets/img/bx_error.png";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import FilterSchedule from "../components/FilterSchedules";
import SortSchedules from "../components/SortSchedules";

dayjs.extend(weekday);
dayjs.extend(localeData);

type SORT = "desc" | "asc";

const Search = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSorting, setOpenSorting] = useState(false);
  const [busType, setBusType] = useState<any>([]);
  const [isPremium, setIsPremium] = useState<any>();
  const [sortType, setSortType] = useState<SORT>("asc");

  const [meta, setMeta] = useState({
    page: 0,
    limit: 12,
    offset: 0,
    total_pages: null,
    total: 0,
  });

  const navigate = useNavigate();

  const getRides = async () => {
    //console.log(dayjs(datePicked).format("YYYY-MM-DD"));
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);
    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [startOfDay.toISOString(), endofDay.toISOString()],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    if (busType && busType.length > 0) {
      //console.log(busType);
      let bus_types = busType?.map((item: any) => `${item?.value}`);
      defaultFilter.push({
        f: "bus_type.id",
        o: "in",
        p: bus_types,
      });
    }

    if (isPremium === false || isPremium === true) {
      defaultFilter.push({
        f: "premium",
        o: "=",
        p: [isPremium],
      });
    }

    try {
      const response = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${meta.limit}&offset=${Number(
          meta.limit * pageIndex
        )}&sorting=price:${sortType}`
      );

      if (response.data?.payload?.items?.length > 0) {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        if (response.data?.payload?.total > meta?.limit) {
          setHasMore(true);
          setSchedules(response.data?.payload?.items);
        } else {
          setSchedules(response.data?.payload?.items);
        }
      } else {
        setMeta((prev) => {
          return {
            ...prev,
            total: response.data?.payload?.total,
          };
        });
        setHasMore(false);
        setSchedules(response.data?.payload?.items);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (page: number, pageSize: number) => {
    setPageIndex(page - 1);
    setMeta((prev) => ({
      ...prev,
      limit: pageSize,
    }));
  };

  useEffect(() => {
    getRides();

    // eslint-disable-next-line
  }, [pageIndex, meta.limit, busType, isPremium, sortType]);

  return (
    <div className="h-full overflow-y-auto">
      <div className="h-[44px] fixed w-full top-0 bg-white">
        <div className="flex mx-[15px] mt-3 items-center justify-center">
          <div className={"flex-[0.2]"}>
            <button onClick={() => navigate(-1)}>
              <ChevronLeftIcon className="w-5 h-5 text-black" />
            </button>
          </div>
          <div
            className={` flex-[0.8] justify-between flex items-center text-oya-gray font-semibold text-lg`}
          >
            <div>Trips for today</div>
            <div className="flex gap-3 items-center">
              <button
                onClick={() => setOpenSorting(true)}
                className="flex gap-1 items-center text-oya-red-100"
              >
                <img
                  className="w-5 h-5"
                  src={require("../../assets/img/sort.png")}
                  alt="Sort"
                />
                <div className="text-sm">Sort</div>
              </button>
              <button
                onClick={() => setOpenFilter(true)}
                className="flex gap-1 items-center text-oya-red-100"
              >
                <img
                  className="w-4 h-4"
                  src={require("../../assets/img/filter.png")}
                  alt="Sort"
                />
                <div className="text-sm">Filter</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-[15px] mt-[6rem]">
        {loading ? (
          <Row className="gap-3 mt-3">
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {schedules.length <= 0 ? (
              <div className=" py-3">
                <div className="flex flex-col justify-center items-center h-full">
                  <div className="border rounded-lg w-full h-[191px] flex flex-col justify-center items-center">
                    <img
                      className="w-[40px] h-[40px] mt-4"
                      src={notFound}
                      alt="404"
                    />
                    <div className="sm:text-xl text-center mt-8 text-gray-500">
                      Sorry, there are no available trips
                    </div>
                    <div className="mt-2 mb-4">
                      <CustomButton
                        onClick={() => {
                          // setBookAdvance(true);
                          // setFindTripActive(false);
                          // setTripCodeActive(false);
                          // if (!token) {
                          //   openAuthModal(true);
                          // }
                          navigate("/advance-ticket");
                        }}
                      >
                        Book for advance ticket
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-3">
                  {schedules.map((item, index) => (
                    <div className="w-full min-w-[220px]" key={index}>
                      <MobileScheduleCard item={item} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        <div className="pt-10 w-full flex justify-end items-end">
          {hasMore && (
            <div className="pagination w-full justify-end items-end">
              <Pagination
                onChange={(page, pageSize) => handlePagination(page, pageSize)}
                defaultPageSize={meta.limit}
                total={meta.total}
              />
            </div>
          )}
        </div>
      </div>

      <FilterSchedule
        isOpen={openFilter}
        handleOpenDrawer={() => setOpenFilter(false)}
        handleReset={() => {
          setIsPremium(undefined);
          setBusType(undefined);
        }}
        applyFilter={(bus_type, comfort_type) => {
          if (bus_type) {
            setBusType(bus_type);
          }

          if (comfort_type) {
            //console.log(comfort_type);
            if (comfort_type === "PREM") {
              setIsPremium(true);
            } else {
              setIsPremium(false);
            }
          }
        }}
      />
      <SortSchedules
        isOpen={openSorting}
        handleClose={() => setOpenSorting(false)}
        sorting={sortType}
        handleSortChange={(value: SORT) => setSortType(value)}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Search;
